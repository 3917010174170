<template>
  <div class="container">
    <poster :poster-list="posterList"  :page-path="pagePath" :module-key="moduleKey" :token="token" />
  </div>
</template>

<script>
import Poster from '../../../components/Poster/Index'

export default {
  name: "UpAndSixDiscount",
  components: {
    Poster
  },
  data() {
    return {
      token: this.$route.query.token,
      posterList: [
     {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20220406114155715.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20220406114142208.png',
          textColor: '#fff',
          tempPoster: '',
          textPoint: [429, 1194],
          wxCode: { width: 188, point: [58, 1120] }
        },
      ],
      pagePath: this.baseHost + '/',
      moduleKey: ''
    }
  },
  created() {
    this.moduleKey = this.token ? 'fc83ccae-27cd-432d-b7df-b9cc74b8d95b' : 'upDjk7Diamond20220406'
  }
}
</script>

<style scoped>
</style>